
import { parse, format } from 'date-fns'

export function toDateMonthYear(dateString) {
    const date = parse(dateString, "yyyy-MM-dd'T'HH:mm:ss", new Date())
    const result = format(date, "dd / MMMM / yyyy")

    return result;
}

export default {toDateMonthYear}