import React, { Component } from "react";
import { NavLink  } from "react-router-dom";

class Navbar extends Component {
  render() {
    return (
        <div>
            <div class="collapse navbar-collapse custom-navmenu" id="main-navbar">
            <div class="container py-2 py-md-5">
                <div class="row align-items-start">
                <div class="col-md-2">
                    <ul class="custom-menu">
                    <li><NavLink className={`nav-link`} to="/">Home</NavLink></li>
                    <li><NavLink className={`nav-link`} to="/blog">Blog</NavLink></li>
                    <li><NavLink className={`nav-link`} to="/about">About</NavLink></li>
                    <li><NavLink className={`nav-link`} to="/contact">Contact</NavLink></li>
                    </ul>
                </div>
                <div class="col-md-5 d-none d-md-block mr-auto">
                    <div class="tweet d-flex">
                    <div>
                        <div class="profile-image"></div>
                        <p><em>i have no special talent, i am just passionately curious. 
                            <br/> 
                            {/* <a href="#">t.co/v82jsk</a> */}
                            </em></p>
                    </div>
                    </div>
                </div>
                <div class="col-md-5 d-none d-md-block">
                    <h3>Hire Me</h3>
                    <p>Want a make ?
                        <br/> - Web
                        <br/> - Apps
                        <br/> - Video
                        <br/> tell me what you need
                        <br/>
                        <a href="mailto:irsyadrsi@gmail.com">irsyadrsi@gmail.com</a></p>
                </div>
                </div>
        
            </div>
            </div>
        
            <nav class="navbar navbar-light custom-navbar">
            <div class="container">
                <a class="navbar-brand" href="index.html">Irsyad Rahman.</a>
                <a href="#" class="burger" data-bs-toggle="collapse" data-bs-target="#main-navbar">
                <span></span>
                </a>
            </div>
            </nav>
        </div>
    );
  }
}

export default Navbar;
