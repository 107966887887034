import React, { Component, useState } from "react";
import axios from "axios";
import Config from "../utils/Config";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { toDateMonthYear } from "../utils/Tools";

class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    //Get data
    axios
      .get(Config.base_url + "/posts", {
        params: {
          categories: "12",
        },
      })
      .then((res) => {
        console.log(res);

        this.setState({
          data: res.data,
        });
      });
  }

  render() {
    return (
      <div>
        <section class="section site-portfolio">
          <div class="container">
            <div class="row mb-5 align-items-end">
              <div class="col-md-6" data-aos="fade-up">
                <h2>Blog</h2>
                <p class="mb-0">
                  “Some beautiful paths can’t be discovered without getting lost.”
                </p>
              </div>
            </div>
            <div
              id="portfolio-grid"
              class="row no-gutter"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {this.state.data.map((value) => (
                // <div class="item col-sm-6 col-md-4 col-lg-4 mb-4">
                //   <a href="work-single.html" class="item-wrap rounded">
                //     <div class="work-info">
                //       <h3>{value.title.rendered}</h3>
                //       {parse(value.content.rendered)}
                //     </div>
                //     <img class="img-fluid" src={value.acf.drive_cover}/>
                //   </a>
                // </div>

                <div class="item">
                  <div class="row">
                    <div class="col-lg-8">
                  <Link
                    to={'/story/'+value.id}
                    class="block-blog-list-item__content">
                    <div class="blog-item">
                        <p class="title">{value.title.rendered}</p>
                        <p class="excerpt">{parse(value.excerpt.rendered)}</p>
                        <p class="date">{toDateMonthYear(value.date)}</p>
                    </div>
                  </Link>
                  </div>
                    <div class="col-lg-4">
                  <Link
                    to={'/story/'+value.id}
                    class="block-blog-list-item__cover-image-container"
                  >
                    <div
                      class="block-blog-list-item__cover-image-wrapper"
                    >
                      <img
                        alt=""
                        src={value.acf.drive_cover}
                        class="img-fluid"
                      />
                    </div>
                  </Link>
                  </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Blog;
