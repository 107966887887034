import "./App.css";
import { createTheme } from "@mui/material/styles";
import React, { Component } from "react";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Story from "./pages/Story";
import Navbar from "./layouts/Navbar";
import Footer from "./layouts/Footer";
import { Routes, Route, Switch, HashRouter, NavLink } from "react-router-dom";

const theme = createTheme();

class App extends Component {
  render() {
    return (
      <HashRouter>
        <div class="">
          <Navbar />
          <main id="main">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/story/:id" element={<Story />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </HashRouter>
    );
  }
}

export default App;
