import React, { Component, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Config from "../utils/Config";
import { toDateMonthYear } from "../utils/Tools";
import parse from "html-react-parser";

function Story() {
  let { id } = useParams();
  const [data, setData] = useState(null);

  axios.get(`${Config.base_url}/posts/${id}`).then((response) => {
    setData(response.data);
  });

  return (
    <section class="section">
      <div class="container">
        <div class="row mb-5 align-items-end" data-aos="fade-up">
          <div
            class="col-sm-12 col-md-12 col-lg-6 mb-6"
            styles="--text: left; --align: flex-start; --justify: flex-end; --position: 6/3/10/8; --m-element-margin: 0 0 16px 0; --element-z-index: 1;"
          >
            <div
              class="text-box block-grid-item__component"
              mobile-block-padding="56px 16px 21px 16px"
              element-width="496"
              element-height="240"
            >
              <p class="body" styless="">
                {data ? toDateMonthYear(data.date) : ""}
              </p>
              <h1 styless="">{data ? parse(data.title.rendered) : ""}</h1>
            </div>
          </div>
          <div
            class="col-sm-12 col-md-12 col-lg-6 mb-6"
            styles="--align: center; --justify: center; --position: 1/9/10/15; --object-fit: cover; --m-element-margin: 0 0 16px 0; --element-z-index: 2;"
          >
            <div
              data-v-00c3b48d=""
              data-v-189c24f4=""
              title=""
              class="block-grid-item__component image"
            >
              <img
                alt=""
                src={data ? data.acf.drive_cover : ""}
                width="100%"
                height="auto"
              />
            </div>
          </div>
        </div>
        <div id="story-content" data-aos="fade-up" data-aos-delay="300">{data ? parse(data.content.rendered) : ""}</div>
      </div>
    </section>
  );
}

export default Story;
