import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <section class="section pb-5">
        <div class="container">
          <div class="row mb-5 align-items-end">
            <div class="col-md-6" data-aos="fade-up">
              <h2>About Me</h2>
              <p class="mb-0">
                Sedikit tentang saya dan apa yang sedang saya lakukan
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 position-relative">
              <div class="experience-wrap" data-aos="fade-zoom-in" data-aos-delay="300">
                  <div class="experience">
                    <span class="d-block">5 Years of experience</span>
                    <a href="#portfolio-section" class="smoothscroll">
                      {" "}
                      <span class="bi bi-arrow-right"></span> See portfolio
                    </a>
                    <a href="#">
                      <span class="bi bi-arrow-right"></span> Download my CV
                    </a>
                  </div>
              </div>
              <figure class="dotted-bg" data-aos="fade-right">
                <div class="reveal-wrap">
                  <span class="cover" styles="margin-left: 102%;"></span>
                  <div
                    class="reveal-content"
                    styles="visibility: inherit; opacity: 1;"
                  >
                    <img
                      src="/assets/img/img_self.jpeg"
                      alt="Image"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </figure>
            </div>
            <div class="col-lg-6 pl-md-5" data-aos="fade-left">
              <h3 class="text-black h5 font-weight-bold mb-3">
                  Perkenalkan saya Irsyad,
              </h3>
              <p>
                  Saya antusias dengan pengembangan IT khususnya Mobile dan Web Application. Saat ini saya bekerja on-site sebagai Web Developer di Wahana Honda dan mengerjakan projek part time untuk beberapa klien dari Instansi maupun Start-up. 
              </p>
              <p>
                  Selain itu saya juga gemar merekam momen dengan kamera yang saya punya. Touring dan traveling menjadi cara saya istirahat sejenak di keseharian di depan layar komputer.
              </p>
              <div class="row my-5">
                <div class="col-12">
                  <h3 class="text-black h5 font-weight-bold mb-3">
                    <span class="reveal-wrap">
                        Sekilas perjalanan saya :
                    </span>
                  </h3>
                </div>
                <div class="col-lg-6">
                  <div class="work-experience">
                      <i class="fas fa-graduation-cap icon"></i>
                      <h5 class="position">Mahasiswa Sitem Informasi</h5>
                      <span class="company">Bina Nusantara</span>
                      <span class="year">2020 - Sekarang</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="work-experience">
                      <i class="fa-solid fa-briefcase icon"></i>
                      <h5 class="position">Freelance Developer</h5>
                      <span class="company">CGA Tech</span>
                      <span class="year">2018-2021</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="work-experience">
                      <i class="fa-solid fa-briefcase icon"></i>
                      <h5 class="position">Web Developer</h5>
                      <span class="company">Wahana Honda</span>
                      <span class="year">2018-Sekarang</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="work-experience">
                      <i class="fa-solid fa-briefcase icon"></i>
                      <h5 class="position">Fullstack Freelancer</h5>
                      <span class="company">Home</span>
                      <span class="year">2018-Sekarang</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
