import React, { Component, useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import Config from "../utils/Config";
import swal from 'sweetalert2'
import FormData from 'form-data'


function FormContact() {

  const { register, handleSubmit, errors, reset } = useForm();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);

  function onSubmit(data) {

    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("your-name", data.name);
    bodyFormData.append("your-email", data.email);
    bodyFormData.append("your-subject", data.subject);
    bodyFormData.append("your-message", data.message);


    axios({
      method: "post",
      url: Config.url_contact,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      setIsLoading(false);
      setIsSuccess(true);
      reset();
      console.log(response);
    })
    .catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }

  return (
    <div class="row">
      <div
        class="col-md-6 mb-5 mb-md-0"
        data-aos="fade-right"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          role="form"
          class="php-email-form"
        >
          <div class="row gy-3">
            <div class="col-md-6 form-group">
              <label for="name">Name</label>
              <input
                type="text"
                {...register("name")}
                class="form-control"
                id="name"
                required=""
              />
            </div>
            <div class="col-md-6 form-group">
              <label for="name">Email</label>
              <input
                {...register("email")}
                class="form-control"
                name="email"
                id="email"
                required=""
              />
            </div>
            <div class="col-md-12 form-group">
              <label for="name">Subject</label>
              <input
                type="text"
                class="form-control"
                {...register("subject")}
                id="subject"
                required=""
              />
            </div>
            <div class="col-md-12 form-group">
              <label for="name">Message</label>
              <textarea
                class="form-control"
                {...register("message")}
                cols="30"
                rows="10"
                required=""
              ></textarea>
            </div>

            <div class="col-md-12 my-3">
              <div className={`loading ${isLoading ? 'd-block' : ''}`}>Loading</div>
              <div class="error-message"></div>
              <div className={`sent-message ${isSuccess ? 'd-block' : ''}`}>
                Your message has been sent. Thank you! 😊
              </div>
            </div>

            <div class="col-md-6 mt-0 form-group">
              <input
                type="submit"
                class="readmore d-block w-100"
                value="Send Message"
              />
            </div>
          </div>
        </form>
      </div>

      <div
        class="col-md-4 ml-auto order-2"
        data-aos="fade-left"
      >
        <ul class="list-unstyled">
          <li class="mb-3">
            <strong class="d-block mb-1">Address</strong>
            <span>
              Jl. Gunung Sahari 8 No 9 <br /> Jakarta Pusat, 10720
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

class Contact extends Component {
  render() {
    return (
      <section class="section pb-5">
        <div class="container">
          <div class="row mb-5 align-items-end">
            <div class="col-md-6" data-aos="fade-up">
              <h2>Contact</h2>
              <p class="mb-0">Silakan ceritakan ide yang anda miliki</p>
            </div>
          </div>
          <FormContact />
        </div>
      </section>
    );
  }
}

export default Contact;
