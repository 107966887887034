import React, { Component, useState } from "react";
import axios from 'axios';
import Config from "../utils/Config";
import parse from 'html-react-parser';

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      age:this.calculateYearsFrom2000()
    };
  }

  calculateYearsFrom2000() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const yearsFrom2000 = currentYear - 2000;
    return yearsFrom2000;
  }

  componentDidMount() {

    //Get data 
    axios.get(`${Config.base_url}/posts?_embed`, {
      params: {
        categories: '11'
      }
    }).then(res => {
        console.log(res);

        this.setState({
          data : res.data
        });
      });
  }

  render() {
    
    return (
      <div>
        <section class="section site-portfolio">
          <div class="container">
            <div class="row mb-5 align-items-center">
              <div class="col-md-12 col-lg-6 mb-4 mb-lg-0" data-aos="fade-up">
                <h2>Good to see you 👋</h2>
                <p class="mb-0">I am a {this.state.age} years old college student, software developer, and ready to be your partner</p>
              </div>
            </div>
            {this.state.data.length == 0 ? '' : 
            <div id="portfolio-grid" class="row no-gutter">
            {
              this.state.data
                .map(value =>
                    <div class="item col-sm-6 col-md-4 col-lg-4 mb-4">
                      <a href="work-single.html" class="item-wrap rounded">
                        <div class="work-info">
                          <h3>{value.title.rendered}</h3>
                          {parse(value.content.rendered)}
                        </div>
                        <img  class="img-fluid" src={value._embedded["wp:featuredmedia"] ? value._embedded["wp:featuredmedia"][0]["source_url"] :  value.acf.drive_cover}/>
                      </a>
                    </div>
                )
            }
            </div>
            }
          </div>
        </section>
        <section class="section">
          <div class="container">
            <div class="row justify-content-center text-center mb-4">
              <div class="col-5">
                <h3 class="h3 heading">My Clients</h3>
                <p>Gratefully to be a part of these awesome clients</p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 col-sm-4 col-md-2">
                <a href="#" class="client-logo"><img src="assets/img/logo-autoinvesta.png" alt="Image" class="img-fluid"/></a>
              </div>
              <div class="col-4 col-sm-4 col-md-2">
                <a href="#" class="client-logo"><img src="assets/img/logo-wahana.png" alt="Image" class="img-fluid"/></a>
              </div>
              <div class="col-4 col-sm-4 col-md-2">
                <a href="#" class="client-logo"><img src="assets/img/logo-angkasapura.png" alt="Image" class="img-fluid"/></a>
              </div>
              <div class="col-4 col-sm-4 col-md-2">
                <a href="#" class="client-logo"><img src="assets/img/logo-malang.png" alt="Image" class="img-fluid"/></a>
              </div>
              <div class="col-4 col-sm-4 col-md-2">
                <a href="#" class="client-logo"><img src="assets/img/logo-perpusnas.png" alt="Image" class="img-fluid"/></a>
              </div>
              <div class="col-4 col-sm-4 col-md-2">
                <a href="#" class="client-logo"><img src="assets/img/logo-cga.png" alt="Image" class="img-fluid"/></a>
              </div>

            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;