import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <div>
                <footer class="footer" role="contentinfo">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="credits">
                                    Create with <a href="https://bootstrapmade.com/">BootstrapMade</a>, React, and Love ✌️
                                </div>
                            </div>
                            <div class="col-sm-6 social text-md-end">
                                <a href="#"><span class="bi bi-twitter"></span></a>
                                <a href="#"><span class="bi bi-facebook"></span></a>
                                <a href="#"><span class="bi bi-instagram"></span></a>
                                <a href="#"><span class="bi bi-linkedin"></span></a>
                            </div>
                        </div>
                    </div>
                </footer>

                <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

            </div>
        );
    }
}

export default Footer;
